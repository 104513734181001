import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Wodka = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #025390, #031446, #025390, #031446, #025390 )",
        icons: "#025390",
        navClass: "wódka",
      }}
      seo={{
        title: "Wódka wschodnia",
        headerTitle: "wodka",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/wodka_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/wodka-wschodnia/",
      }}
    >
      <PortfolioHeader name="wodka" height="276" />
      <section className="container-fluid wodka_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Wódka Wschodnia</h1>
              <ul>
                <li>Etykieta</li>
                <li>Przygotowanie plików do druków</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Dla jednego z naszych Klientów wykonaliśmy projekt etykiety
                wysokogatunkowej wódki czystej klasy premium.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid wodka_section_3">
        <div className="row">
          <div className="col-md-4 offset-md-1 col_0">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/wodka_main1.png")}
              alt=""
            />
          </div>
          <div className="col-md-5 offset-md-1 col_1">
            <div className="inner">
              <h4>Styl</h4>
              <p>
                Postawiliśmy na nowoczesną stylistykę oddającą jednak hołd
                pozytywnej prostocie płynącej z zakorzenionej w wiekach tradycji
                wschodniej..
              </p>
            </div>
          </div>
          <div className="col-md-1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/wodka_icecube1.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid wodka_section_4">
        <div className="row">
          <div className="col-md-1">
            <img
              className="img-fluid img_1"
              src={require("../../assets/img/portfolio/wodka_icecube2.png")}
              alt=""
            />
          </div>
          <div className="col-md-4 col_2">
            <div className="inner">
              <h4>Jak?</h4>
              <p>
                Aby osiągnąć ten efekt opracowaliśmy odpowiednią kolorystykę
                (odcień granatu, biel, złoto), liternictwo (dobór fontu –
                połączenie fontów o różnym charakterze) i kompozycję (układ
                elementów graficznych, elegancki wygląd, delikatne zdobienia)..
              </p>
            </div>
          </div>
          <div className="col-md-5 offset-md-2 col_1">
            <img
              className="img-fluid img_2"
              src={require("../../assets/img/portfolio/wodka_main2.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Wodka;
